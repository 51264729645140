import { gql } from '@/shared/graphql/generated';

export const getStringSearchResults = gql(/* GraphQL */ `
  query GetStringSearchResults($searchString: String!) {
    swaps: allSwapRequests(
      filter: { destinationAddress: { equalToInsensitive: $searchString } }
      orderBy: ID_DESC
      first: 5
    ) {
      nodes {
        nativeId
      }
    }
    swapChannels: allSwapChannels(
      filter: {
        or: [
          { depositAddress: { equalToInsensitive: $searchString } }
          { destinationAddress: { equalToInsensitive: $searchString } }
        ]
      }
      orderBy: ID_DESC
      first: 5
    ) {
      nodes {
        issuedBlockId
        sourceChain
        channelId
      }
    }
    blocks: allBlocks(filter: { hash: { equalTo: $searchString } }) {
      nodes {
        id
      }
    }
    txRefs: allTransactionRefs(filter: { ref: { equalTo: $searchString } }) {
      nodes {
        swap: swapRequestBySwapRequestId {
          nativeId
        }
        broadcast: broadcastByBroadcastId {
          egress: egressesByBroadcastId {
            nodes {
              swapRequests: swapRequestsByEgressId {
                nodes {
                  nativeId
                }
              }
            }
          }
        }
        lpDeposit: liquidityDepositByLiquidityDepositId {
          channel: liquidityDepositChannelByLiquidityDepositChannelId {
            chain
            channelId
            issuedBlockId
          }
        }
      }
    }
    validators: allAccounts(
      filter: {
        role: { equalTo: VALIDATOR }
        or: [
          { alias: { includesInsensitive: $searchString } }
          { idSs58: { equalToInsensitive: $searchString } }
        ]
      }
      orderBy: [ALIAS_ASC, ID_SS58_ASC]
      first: 5
    ) {
      nodes {
        idSs58
        alias
      }
    }
    brokers: allAccounts(
      filter: {
        role: { equalTo: BROKER }
        or: [
          { alias: { includesInsensitive: $searchString } }
          { idSs58: { equalToInsensitive: $searchString } }
        ]
      }
      orderBy: [ALIAS_ASC, ID_SS58_ASC]
      first: 5
    ) {
      nodes {
        idSs58
        alias
      }
    }
    lps: allAccounts(
      filter: {
        role: { equalTo: LIQUIDITY_PROVIDER }
        or: [
          { alias: { includesInsensitive: $searchString } }
          { idSs58: { equalToInsensitive: $searchString } }
        ]
      }
      orderBy: [ALIAS_ASC, ID_SS58_ASC]
      first: 5
    ) {
      nodes {
        idSs58
        alias
      }
    }
    extrinsics: allExtrinsics(filter: { hash: { equalTo: $searchString } }) {
      nodes {
        blockId
        indexInBlock
      }
    }
  }
`);

export const getIndexInBlockSearchResults = gql(/* GraphQL */ `
  query GetIndexInBlockSearchResults($blockId: Int!, $indexInBlock: Int!) {
    events: allEvents(
      filter: { blockId: { equalTo: $blockId }, indexInBlock: { equalTo: $indexInBlock } }
    ) {
      nodes {
        blockId
        indexInBlock
      }
    }
    extrinsics: allExtrinsics(
      filter: { blockId: { equalTo: $blockId }, indexInBlock: { equalTo: $indexInBlock } }
    ) {
      nodes {
        blockId
        indexInBlock
      }
    }
  }
`);

export const getNumericSearchResults = gql(/* GraphQL */ `
  query GetNumericSearchResults($intValue: Int!, $bigIntValue: BigInt!) {
    swaps: allSwapRequests(filter: { nativeId: { equalTo: $bigIntValue } }) {
      nodes {
        nativeId
      }
    }
    blocks: allBlocks(filter: { id: { equalTo: $intValue } }) {
      nodes {
        id
      }
    }
    epochs: allEpoches(filter: { id: { equalTo: $intValue } }) {
      nodes {
        id
      }
    }
  }
`);

export const getChannelSearchResults = gql(/* GraphQL */ `
  query GetChannelSearchResults(
    $issuedBlockId: Int!
    $chain: ChainflipChain!
    $channelId: BigInt!
  ) {
    swapChannels: allSwapChannels(
      filter: {
        issuedBlockId: { equalTo: $issuedBlockId }
        sourceChain: { equalTo: $chain }
        channelId: { equalTo: $channelId }
      }
    ) {
      nodes {
        issuedBlockId
        sourceChain
        channelId
      }
    }
    lpChannels: allLiquidityDepositChannels(
      filter: {
        issuedBlockId: { equalTo: $issuedBlockId }
        chain: { equalTo: $chain }
        channelId: { equalTo: $channelId }
      }
    ) {
      nodes {
        issuedBlockId
        chain
        channelId
      }
    }
  }
`);
